

@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 200;
  src: url('../fonts/montserrat/Montserrat-ExtraLight.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-ExtraLight.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-ExtraLight.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/montserrat/Montserrat-Regular.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Regular.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('../fonts/montserrat/Montserrat-Bold.woff2') format('woff2'),
    url('../fonts/montserrat/Montserrat-Bold.woff') format('woff'),
    url('../fonts/montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: url('../fonts/material-icons/material-icons.woff2') format('woff2');
} 

.material-icons {
  font-family: 'Material Icons';
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  font-feature-settings: 'liga';
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.modalOpen {
  position: fixed;
}

/* new font family Sohne - Kraftig.otf and Leicht.otf
 * Sohne - Kraftig.otf
 */
 @font-face {
  font-display: swap;
  font-family: 'Sohne';
  font-style: normal;
  font-weight: 500;
  src: local('Sohne'), url('../fonts/sohne/Söhne-Kräftig.otf') format('opentype');
}
/* new font family Sohne - Kraftig.otf and Leicht.otf
 * Sohne - Leicht.otf
 */
@font-face {
  font-display: swap;
  font-family: 'Sohne';
  font-style: normal;
  font-weight: 300;
  src: local('Sohne'), url('../fonts/sohne/Söhne-Leicht.otf') format('opentype');
}